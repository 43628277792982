import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import styled from "styled-components";
import PropTypes from "prop-types";
import SEO from "../components/seo";
import Container from "../components/container.js";
import Image from "../components/image.js";
import Columns from "../components/columns.js";
import Column from "../components/column.js";
import GutenbergBlocks from "../components/gutenberg-blocks";
import BlogCard from "../components/blogcard";
import { _ } from "../utils/i18n";
import { modifyColor } from "../utils/helpers";
import Breadcrumbs from "../components/breadcrumbs";
import BlogcardContainer from "../components/blogcard-container";

const InfoBar = styled.div`
  display: flex;
  margin: 2rem 0;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    flex-direction: column;
    margin: 1rem 0;
  }
`;
const Date = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2rem;
  border-right: 2px solid
    ${({ theme }) => modifyColor(theme.colors.bodyText, 140)};
  &:last-child {
    border-right: none;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    border: none;
    padding: 0.5rem 0;
  }
`;
const Title = styled.h1`
  margin-top: 1.5rem;
  color: ${({ theme }) => theme.colors.primary};
  @media (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    font-size: 22px;
  }
`;
const Author = styled.div`
  display: flex;
  border-right: 2px solid
    ${({ theme }) => modifyColor(theme.colors.bodyText, 140)};
  padding-right: 2rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    border: none;
    padding-bottom: 1rem;
  }
  img {
    height: 55px;
    width: 55px;
    border-radius: 100%;
    margin-right: 1rem;
  }
`;
const RelatedPostHeadline = styled.h2`
  text-align: left;
  padding-left: 1.8rem;
  font-weight: 100;
  font-size: 28px;
  margin-bottom: 1rem;
`;

const Article = ({
  data: {
    wpPost: {
      title,
      excerpt,
      featuredImage,
      author,
      date,
      blocks,
      crbRelatedPosts,
      seo,
      modified,
    },
  },
}) => {
  return (
    <Layout itemScope itemType="https://schema.org/Blog">
      <SEO
        title={seo?.title || title}
        description={seo?.metaDesc || excerpt}
        breadcrumbs={seo?.breadcrumbs}
      />
      <Container maxWidth="sm" disableAnimation disableMargin>
        <Breadcrumbs breadcrumbList={seo?.breadcrumbs} />
        <Columns>
          <Column
            width="100%"
            style={{ backgroundColor: "transparent", paddingInline: 0 }}
          >
            {featuredImage?.node && (
              <Image image={featuredImage.node} itemProp="image" />
            )}
          </Column>
        </Columns>
      </Container>
      <Container maxWidth="sm" disableAnimation disableMargin>
        <Title itemProp="name">{title}</Title>
        <InfoBar>
          <Author>
            <img src={author.node?.avatar?.url} />
            {author.node?.firstName && author.node.lastName ? (
              <span>
                {author.node?.firstName}
                <br />
                {author.node?.lastName}
              </span>
            ) : author.node.firstName ? (
              <span>{author.node?.firstName}</span>
            ) : (
              <span>{author.node?.name}</span>
            )}
          </Author>
          <Date itemProp="datePublished">
            <span style={{ fontWeight: "bold" }}>{_("Publisert")}: </span>
            {date}
          </Date>
          <Date itemProp="dateModified">
            <span style={{ fontWeight: "bold" }}>{_("Oppdatert")}: </span>
            {modified}
          </Date>
        </InfoBar>
        <GutenbergBlocks blocks={blocks} />
      </Container>
      <Container maxWidth="sm" disableAnimation>
        {crbRelatedPosts?.length > 0 && (
          <RelatedPostHeadline style={{ paddingLeft: 0 }}>
            {_("Les også")}:
          </RelatedPostHeadline>
        )}
        <BlogcardContainer postLayout="grid" columns="3">
          {crbRelatedPosts?.map((post, index) => (
            <BlogCard
              key={index}
              title={post.title}
              date={post.date}
              excerpt={post.excerpt}
              link={post.uri}
              image={post?.featuredImage?.node}
              author={post.author.node.name}
              layout="grid"
              displayFeaturedImage={true}
              displayPostContent={true}
              excerptLength={15}
            />
          ))}
        </BlogcardContainer>
      </Container>
    </Layout>
  );
};

Article.propTypes = {
  data: PropTypes.object,
};

export default Article;

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      blocks {
        ...GutenbergBlocks
      }
      author {
        node {
          name
          firstName
          lastName
          avatar {
            url
          }
        }
      }
      title
      uri
      excerpt
      seo {
        metaDesc
        title
        breadcrumbs {
          text
          url
        }
      }
      date(locale: "nb-NO", formatString: "DD MMMM yyyy")
      modified(locale: "nb-NO", formatString: "DD MMMM yyyy")
      featuredImage {
        node {
          focalPoint {
            objectPosition
          }
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      crbRelatedPosts {
        date(formatString: "DD.MM.YYYY")
        excerpt
        title
        uri
        featuredImage {
          node {
            altText
            focalPoint {
              objectPosition
            }
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        author {
          node {
            name
          }
        }
      }
    }
  }
`;
